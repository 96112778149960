<template>
  <div
    class="input-component field"
    :class="{
      filled: isFilled,
      hovered: isHovered,
      focused: isFocused,
      disabled: disabled,
      error: error,
      'is-small': small,
      'is-horizontal': isHorizontal,
    }"
  >
    <label v-if="showLabel" class="label">
      {{ label }}
    </label>

    <div
      class="input-container"
      :class="{ 'has-icon': hasIcon, 'clickable-icon': clickableIcon }"
    >
      <input
        ref="inputEl"
        :value="inputValue"
        :type="type"
        :name="name"
        :disabled="disabled"
        :required="required"
        :readonly="readonly"
        :maxlength="maxlength"
        class="input"
        :placeholder="placeholder"
        v-bind="extras"
        @input.prevent="(e) => (inputValue = e.target.value)"
        @keydown.enter.stop.prevent=""
        @keyup.enter.stop.prevent="emitEnter"
        @mouseover="() => (isHovered = true)"
        @mouseleave="() => (isHovered = false)"
        @focus="handleFocus"
        @blur="handleBlur"
      />
      <slot />
    </div>

    <span v-if="showMessage" class="info">
      {{ message }}
    </span>
  </div>
</template>

<script>
import { ref, computed, watch, watchEffect, onMounted } from "vue";

export default {
  props: {
    placeholder: String,
    message: String,
    label: String,
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: String,
      default: "",
    },
    showMessage: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    isSynchronized: {
      type: Boolean,
      default: false,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    clickableIcon: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    extras: {
      type: Object,
      default: {},
    },
    onFocus: {
      type: Function,
      default: () => {},
    },
    onBlur: {
      type: Function,
      default: () => {},
    },
  },
  emits: ["input", "enterPressed"],
  setup(props, { emit }) {
    const inputValue = ref(props.value);
    const isFocused = ref(false);
    const isHovered = ref(false);

    const isFilled = computed(() => !!inputValue.value);

    const inputEl = ref(null);

    onMounted(() => {
      watch(inputValue, (value) => {
        emit("input", value);
      });
    });

    watchEffect(() => {
      if (props.isSynchronized) inputValue.value = props.value;
    });

    const emitEnter = () => {
      emit("enterPressed", inputValue);
    };

    const handleFocus = () => {
      isFocused.value = true;
      props.onFocus();
    };

    const handleBlur = () => {
      isFocused.value = false;
      props.onBlur();
    };

    return {
      inputValue,
      isFilled,
      isFocused,
      isHovered,
      inputEl,
      emitEnter,
      handleFocus,
      handleBlur,
    };
  },
};
</script>
