import strftime from "@/utils/strftime";

// Formats strings to CPF '000.000.000-00' format
const formatCpf = (cpf) => {
  if (typeof cpf !== "string") return "";

  return cpf
    .replace(/\D+/g, "")
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

// Formats strings to CNPJ '00.000.000/0000-00' format
const formatCnpj = (cnpj) => {
  if (typeof cnpj !== "string") return "";

  return cnpj
    .replace(/\D+/g, "")
    .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
};

// Formats strings to CPF or CNPJ format depending on the input length
const formatCpfCnpj = (cpf_cnpj) => {
  if (typeof cpf_cnpj !== "string") return "";

  switch (cpf_cnpj.replace(/\D+/g, "").length) {
    case 11:
      return formatCpf(cpf_cnpj);
    case 14:
      return formatCnpj(cpf_cnpj);
    default:
      return cpf_cnpj;
  }
};

// Formats strings to one of the phone formats depending on the input length
const formatPhone = (phone) => {
  if (typeof phone !== "string") return "";

  phone = phone.replace(/\D+/g, "");
  switch (phone.length) {
    case 8:
      return phone.replace(/(\d{4})(\d{4})/, "$1-$2");
    case 9:
      return phone.replace(/(\d{5})(\d{4})/, "$1-$2");
    case 10:
      return phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    case 11:
      return phone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");
    default:
      return phone;
  }
};

const formatPhoneWithDDI = (phone, ddi) => {
  return `+${ddi} ${ddi == "55" ? formatPhone(phone) : phone}`;
};

const getMonthLabel = (month) => {
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];
  return months[month - 1] || "";
};

// Formats dates to MMM/YY where MMM is the first 3 letters of the month
const formatMonthYear = (date) => {
  const [month, year] = date.split("/");
  return `${getMonthLabel(+month)}/${year}`;
};

const formatPercentage = (percentage, digits = 2) => {
  const formatter = new Intl.NumberFormat("pt-BR", {
    maximumFractionDigits: digits,
  });
  return formatter.format(Number(percentage) * 100) + "%";
};

// Formats strings to currency 'R$ 000.000,00' format using Intl.NumberFormat
const currencyFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});
const formatCurrency = currencyFormatter.format;

// Formats Dates/strings to most common formats
// DD/MM/YYYY
const formatDate = (date) => strftime(date, "%d/%m/%Y");
// HH:MM
const formatTime = (date) => strftime(date, "%H:%M");
// DD/MM/YYYY HH:MM
const formatDatetime = (date) => strftime(date, "%d/%m/%Y %H:%M");
//DD de MMM de YYYY
const formatLongDate = (date) => strftime(date, "%d de %B de %Y");

//Formats numbers to short labels
const getFormattedValue = (value) => {
  const labels = ["", "mil", "milhões"];
  const label = labels[Math.floor(Math.log10(value) / 3)] || "";
  const returnValue = value / 10 ** (3 * labels.indexOf(label));
  return {
    value: returnValue.toFixed(label !== "" ? 1 : 0).replace(".0", ""),
    label,
  };
};

// Formats strings to CEP '00000-000' format
const formatCep = (cep) => {
  if (typeof cep !== "string") return "";

  return cep.replace(/\D+/g, "").replace(/(\d{5})(\d{3})/, "$1-$2");
};

// Formats strings to NIRF/CIB '*.***.***-*'
const formatNirfCib = (nirf_cib) => {
  if (typeof nirf_cib !== "string") return "";

  return nirf_cib.replace(/(\w{1})(\w{3})(\w{3})(\w{1})/, "$1.$2.$3-$4");
};

export {
  formatCpf,
  formatCnpj,
  formatCpfCnpj,
  formatPhone,
  formatPercentage,
  formatCurrency,
  formatDate,
  formatTime,
  formatDatetime,
  formatLongDate,
  getFormattedValue,
  formatMonthYear,
  getMonthLabel,
  formatCep,
  formatNirfCib,
  formatPhoneWithDDI,
};
