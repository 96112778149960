<template>
  <div
    class="select-component"
    :class="{
      filled: optionSelected,
      hovered: isHovered,
      focused: isFocused,
      error: error || selectError,
      'is-small': small,
      'is-active': isDropdownOpen,
    }"
    @click="isDropdownOpen = !disabled"
    @mouseleave="isDropdownOpen = false"
  >
    <label v-if="showLabel" class="select-label"> {{ label }} </label>
    <div class="dropdown-trigger">
      <button
        class="button trigger-button is-fullwidth"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        :disabled="disabled"
        @mouseover="() => (isHovered = true)"
        @mouseleave="() => (isHovered = false)"
        @click.prevent
      >
        <span :class="{ filled: optionSelected != '' }">
          {{ selectedLabel }}
        </span>
        <span class="icon is-small">
          <ExpandMore />
        </span>
      </button>

      <span v-if="showMessage || selectError" class="info">
        {{ message || selectErrorMsg }}
      </span>
    </div>
    <div class="dropdown-menu">
      <div class="dropdown-content">
        <div class="filter-content">
          <AppInput
            type="text"
            placeholder="Digite"
            :small="true"
            label="Filtro"
            :isSynchronized="true"
            @input="(v) => (query = v)"
          />
        </div>

        <label
          v-for="(option, key) in visibleOptions"
          :key="option + key"
          class="dropdown-item"
        >
          <div class="radio">
            <input
              v-model="optionSelected"
              type="radio"
              :value="option.value"
              :name="name"
              :disabled="option.disabled"
            />
            <span class="radio-label">
              {{ option.label }}
            </span>
          </div>
        </label>

        <slot />
      </div>
    </div>
    <input
      v-model="inputHandle"
      class="input-handle"
      :required="required"
      @focus="selectErrorCallback"
    />
  </div>
</template>

<script>
import ExpandMore from "icons/cached/expand_more.svg";
import AppInput from "components/ui/AppInput.vue";

import { computed, ref, watch, watchEffect } from "vue";

export default {
  components: {
    ExpandMore,
    AppInput,
  },
  props: {
    message: {
      type: String,
      default: "Campo obrigatório",
    },
    options: Array,
    label: String,
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Selecione",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showMessage: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const isFocused = ref(false);
    const isHovered = ref(false);
    const isDropdownOpen = ref(false);
    const inputHandle = ref(props.value);
    const query = ref("");

    let standardOptions = computed(() => {
      if (props.options.length == 0) return props.options;

      if (
        typeof props.options[0] != "string" &&
        !(props.options[0] instanceof String)
      )
        return props.options;

      let currentOptions = props.options.map((option) => {
        return {
          value: option.toString(),
          label: option.toString(),
          disabled: false,
        };
      });

      return currentOptions;
    });

    const visibleOptions = computed(() => {
      return standardOptions.value.reduce(function (r, e) {
        if (e.label.toLowerCase().includes(query.value.toLowerCase()))
          r.push(e);

        return r;
      }, []);
    });

    const optionSelected = ref(props.value || "");

    watchEffect(() => {
      optionSelected.value = props.value || "";
    });

    watch(optionSelected, (value) => {
      selectErrorCallback();
      emit("change", value);
      inputHandle.value = optionSelected.value;
    });

    const selectError = ref(false);
    const selectErrorMsg = ref("");
    watch(
      () => props.required,
      (required) => {
        if (!required && !!selectError.value) {
          selectError.value = false;
          selectErrorMsg.value = "";
        }
      },
    );

    const selectErrorCallback = () => {
      if (props.required) {
        if (optionSelected.value != "") {
          selectError.value = false;
          selectErrorMsg.value = "";
        } else {
          selectError.value = true;
          selectErrorMsg.value = "Campo obrigatório";
        }
      }
    };

    const selectedLabel = computed(() => {
      const selectedIndex = standardOptions.value.findIndex(
        (selected) => selected.value.toString() == optionSelected.value,
      );

      return selectedIndex < 0
        ? props.placeholder
        : standardOptions.value[selectedIndex].label;
    });

    return {
      isFocused,
      isHovered,
      optionSelected,
      isDropdownOpen,
      selectErrorCallback,
      selectError,
      selectErrorMsg,
      inputHandle,
      visibleOptions,
      query,
      standardOptions,
      selectedLabel,
    };
  },
};
</script>
