<template>
  <div
    class="message my-4"
    :class="{
      'is-hidden': !open,
      'is-danger': type == 'alert',
      'is-success': type == 'notice',
    }"
  >
    <div class="message-body">
      {{ message }}
      <CloseIcon
        @click.prevent="
          () => {
            if (!isSynchronized) open = !open;
            $emit('close');
          }
        "
      />
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";

import CloseIcon from "icons/cached/close.svg";

export default {
  components: {
    CloseIcon,
  },
  props: {
    message: String,
    isOpen: Boolean,
    isSyncronized: {
      type: Boolean,
      default: false,
    },
    type: {
      default: "alert",
      type: String,
    },
  },
  emits: ["close"],
  setup(props) {
    const open = ref(props.isOpen);

    watchEffect(() => {
      if (props.isSyncronized) open.value = props.isOpen;
    });

    return {
      open,
    };
  },
};
</script>

<style></style>
