<template>
  <div class="pagination-container my-4">
    <span class="see-more">Veja mais páginas:</span>
    <div class="pages-container">
      <a
        title="Página anterior"
        class="svg-container"
        :class="{ disabled: currentPage == 1 }"
        :href="
          unpaginatedUrl + `page=${currentPage - 1 < 1 ? 1 : currentPage - 1}`
        "
      >
        <ArrowLeftIcon @click="handlePageChange($event, currentPage - 1)" />
      </a>

      <a
        v-for="(page, i) in visiblePages"
        :key="i"
        :class="{ selected: page == currentPage }"
        class="pages-numbers mx-1"
        :href="unpaginatedUrl + `page=${page}`"
        @click="handlePageChange($event, page)"
      >
        <span class="has-text-weight-bold">{{ page }}</span>
      </a>

      <a
        title="Próxima página"
        class="svg-container"
        :class="{ disabled: currentPage >= totalPages }"
        :href="
          unpaginatedUrl +
          `page=${currentPage + 1 > totalPages ? totalPages : currentPage + 1}`
        "
      >
        <ArrowRightIcon @click="handlePageChange($event, currentPage + 1)" />
      </a>
    </div>
  </div>
</template>

<script>
import ArrowLeftIcon from "icons/cached/keyboard_arrow_left.svg";
import ArrowRightIcon from "icons/cached/keyboard_arrow_right.svg";
import { computed, ref, watch, watchEffect } from "vue";

export default {
  components: {
    ArrowLeftIcon,
    ArrowRightIcon,
  },
  props: {
    customHandler: Boolean,
    totalPages: Number,
    currentPage: Number,
    siblingCount: {
      type: Number,
      default: 5,
    },
  },
  emits: ["changePage"],
  setup(props, { emit }) {
    const currentUrl = ref(window.location.href);
    const unpaginatedUrl = computed(() => {
      let unpaginated = currentUrl.value.replace(/[?|&]page=\d+/, "");
      return unpaginated.includes("?") ? unpaginated + "&" : unpaginated + "?";
    });

    const pages = ref(new Array(props.totalPages));

    watchEffect(() => {
      pages.value = new Array(props.totalPages);
      currentUrl.value = window.location.href;
    });

    const visiblePages = computed(() => {
      if (props.totalPages > props.siblingCount) {
        if (props.currentPage > Math.ceil(props.siblingCount / 2)) {
          let visible = [];

          for (
            let i = props.currentPage - Math.floor(props.siblingCount / 2);
            i < props.currentPage + Math.ceil(props.siblingCount / 2);
            i++
          ) {
            if (i > props.totalPages) break;

            visible.push(i);
          }

          return visible;
        } else {
          return new Array(props.siblingCount)
            .fill()
            .map((_, index) => index + 1);
        }
      }

      let visible = [];
      let allPages = props.totalPages ? props.totalPages : 1;

      for (let i = 1; i <= allPages; i++) {
        visible.push(i);
      }

      return visible;
    });

    const handlePageChange = (e, page) => {
      if (props.customHandler) {
        e.preventDefault();

        if (
          page != props.currentPage &&
          page >= 1 &&
          page <= props.totalPages
        ) {
          emit("changePage", page);
        }
      }
    };

    return {
      currentUrl,
      unpaginatedUrl,
      pages,
      visiblePages,
      handlePageChange,
    };
  },
};
</script>

<style></style>
